import { Link } from "react-router-dom";
export default function HomeBanner(props) {
    return (
        <>
            <div className="col-12 p-4" style={{ backgroundColor: "#fff" }}>
                <div className="position-relative text-center mb-2">
                    <h2>Key Highlights</h2>
                    <p class="fs-5 fw-light">Empowering Travel Agent with a Global B2B Online Reservation System</p>
                </div>
                <div className="key-highlights">
                    <div className="highlights-container">
                        <div className="highlight-item" style={{ backgroundColor: "#031b34" }}>
                            <div className="icon rail-icon"></div>
                            <h3>5000+</h3>
                            <p>Flight Journeys across world</p>
                        </div>
                        <div className="highlight-item" style={{ backgroundColor: "#990011" }}>
                            <div className="icon hotel-icon"></div>
                            <h3>400,000+</h3>
                            <p>Hotel and Apartment Rooms Worldwide</p>
                        </div>
                        <div className="highlight-item" style={{ backgroundColor: "#031b34" }}>
                            <div className="icon building-icon"></div>
                            <h3>1000+</h3>
                            <p>Directly Contracted Preferred Hotels</p>
                        </div>
                        <div className="highlight-item" style={{ backgroundColor: "#990011" }}>
                            <div className="icon sightseeing-icon"></div>
                            <h3>20,000+</h3>
                            <p>Sightseeing items and over 5000 Tours in 500 cities</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 p-4" style={{ backgroundColor: "#fff" }}>
                <div className="position-relative text-center mb-2">
                    <h2>Start your travel business with LandAir</h2>
                    <p class="fs-5 fw-light">Experience the Future of Travel Distribution: A Rewarding Journey for Travel Partners</p>
                </div>
                <div className="key-highlights">
                    <div className="highlights-container">
                        <div className="highlight-items">
                            <div className="icon rail-icon"></div>
                            <h3>Easy Bookings</h3>
                            <p>LandAir simplifies the travel planning process, offering a seamless platform to effortlessly organize your next adventure.</p>
                        </div>
                        <div className="highlight-items">
                            <div className="icon hotel-icon"></div>
                            <h3>Best Destinations</h3>
                            <p>From iconic landmarks to hidden gems, our curated selection of destinations promises unforgettable adventures.</p>
                        </div>
                        <div className="highlight-items">
                            <div className="icon building-icon"></div>
                            <h3>Travel Guides</h3>
                            <p>Packed with insider tips, hidden gems, and expert advice, our guides are your ultimate companion to unlocking the essence of each destination</p>
                        </div>
                        <div className="highlight-items">
                            <div className="icon sightseeing-icon"></div>
                            <h3>Friendly Support</h3>
                            <p>At your service around the clock, our team is dedicated to ensuring your journey is smooth and stress-free.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}