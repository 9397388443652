import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import "react-datepicker/dist/react-datepicker.css";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import { handleSuccess, handleError } from "../Layout/CustomAlerts";
import { useNavigate } from "react-router-dom";
import Agenttab from "./AgentTab";
import AgentMobileTab from "./AgentMobileTab";
export default function CompanyBankAccount() {
    
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>
                <div className="dashboard-menus border-top d-none d-lg-block" style={{ backgroundColor: 'cadetblue' }}>
                    <div className="container">
                        <Agenttab></Agenttab>
                    </div>
                </div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Bank Account Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="gray-simple position-relative">
                    <div className="container">
                        <div className="row align-items-start justify-content-between gx-xl-4">
                            <div className="col-xl-6 col-lg-8 col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-lock me-2" />Bank Account Details</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row align-items-center justify-content-start">
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <h4>HDFC BANK</h4>
                                                <p>Account Name : LAND AIR TRAVELS PVT LTD</p>
                                                <p>Account Number : 59296761567899</p>
                                                <p>IFSC CODE : HDFC0005096</p>
                                                <p>ADDRESS:	BEGUMPET,HYDERABAD, T.S - INDIA </p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}